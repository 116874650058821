//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "About",
  data() {
    return {
      uid: "",
      show2: false,
      showbuy: false,
      showpai: false,
      tid: 0,
      items: [],
      auctionlist: [],
      conflux: null,
      zhuzaozhe: "",
      no: "",
      currbizhi: "",
      thumb: "",
      currtitle: "",
      curruid: "",
      cfuid: "", // 作品 cf_address
      address: "", // 登录者 cf_address
      currren: "",
      jimairen:"",
      price: "",
      increaseprice: "",
      paiprice: "",
      paicount: 0, // 出价次数
      endtime: 0,
    };
  },
  mounted() {
    window.setMybalance = function (val) {};
    window.setMyearned = function (val) {};
    window.setMyprofit = function (val) {};
    window.setMyart = function (val) {};
    window.setMycoinname = function (val) {};
    window.setStakingbalance = function (val) {};
    this.screenWidth = 600; //document.body.clientWidth; // 屏幕宽
    this.init();
  },
  methods: {
    async init() {
      this.$nextTick(function () {
        if (document.body.clientWidth >= 768) {
          this.$router.replace({ path: "/sell" });
        }
        this.address = localStorage.getItem("_address");
        this.$axios.defaults.headers.common.Authorization = this.address;
        this.uid = localStorage.getItem("nft_acc_id")
          ? localStorage.getItem("nft_acc_id")
          : "";

        this.$axios.get(this.$store.state.api + "/auctionokall").then((response) => {
          this.auctionlist = response.data;
        });
      });
    },
    // 显示购买信息
    showbuyy(price, tid, uid, tit, img, picpath, bizhi, zhuzaozhe) {
      if (uid === this.uid) {
        return;
      }

      this.showbuy = true;

      this.thumb =
        "http://www.artii.top/nftpic/" +
        picpath +
        "/" +
        img.replace("/home/xvx/www/", "");
      this.currtitle = tit;
      this.price = price;
      this.tid = tid;
      this.curruid = uid;
      this.currbizhi = bizhi;
      try {
        this.zhuzaozhe =
          zhuzaozhe.substring(0, 6) + "..." + zhuzaozhe.substring(45, zhuzaozhe.lenght);
      } catch (error) {}
    },
    // 显示拍卖信息
    async showpaii(
      price,
      increaseprice,
      tid,
      uid,
      tit,
      img,
      picpath,
      bizhi,
      zhuzaozhe,
      endtime
    ) {
      this.thumb =
        "http://www.artii.top/nftpic/" +
        picpath +
        "/" +
        img.replace("/home/xvx/www/", "");

      this.showpai = true;

      const pai = await window.SHOPPAI.shopMap(tid);
      console.log(pai);
      this.paicount = +pai[4];
      this.cfuid = pai[2];
      // +this.$drip.fromCFX(pai[3])   this.$drip(pai[3]).toCFX()

      if(+this.paicount > 0) {
        this.currren = pai[2].substring(0, 6) + "..." + pai[2].substring(45, pai[2].lenght);
      } else {
        this.currren = '';
      }
      this.jimairen = pai[0].substring(0, 6) + "..." + pai[0].substring(45, pai[0].lenght);

      this.currtitle = tit;
      this.price = price;
      this.increaseprice = increaseprice;
      this.tid = tid;
      this.curruid = uid;
      this.currbizhi = bizhi;
      this.endtime = endtime;
      try {
        this.zhuzaozhe =
          zhuzaozhe.substring(0, 6) + "..." + zhuzaozhe.substring(45, zhuzaozhe.lenght);
      } catch (error) {}
    },
    // 关闭拍卖信息
    closespai() {
      this.zhuzaozhe = "";
      this.showpai = false;
    },
    // 关闭购买信息
    closes() {
      this.zhuzaozhe = "";
      this.showbuy = false;
    },
    // 购买产品
    async buyy() {
      const price = this.price;
      let tid = this.tid;
      const uid = this.uid; //  收购者

      let accounts = null;
        let _address = "";
        try {
          accounts = await window.conflux.enable();
        } catch (error) {}
        // 新版本手机
        if (window.isArrayFn(accounts)) {
          _address = accounts[0];
        } else {
          const accounts2 = await window.conflux.send({ method: "cfx_accounts" });
          _address = accounts2.result[0];
        }
        window._address = _address;

      localStorage.setItem("_address", _address);

      document.getElementById("accname").innerHTML =
        '<div class="qb_btn">' +
        _address.substring(0, 6) +
        "..." +
        _address.substring(45, _address.lenght) +
        "</div>";

      tid = +tid;
      console.log(uid);

      if (this.curruid === uid) {
        return;
      }

      window.approvalshop();

      this.showbuy = false;
      this.show2 = true;
      try {
        let collateral = await window.SHOP.buySellerTicket(tid).estimateGasAndCollateral({
          from: window._address,
          value: +this.$drip.fromCFX(price),
        });

        const buy = await window.SHOP.buySellerTicket(tid)
          .sendTransaction({
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
            value: +this.$drip.fromCFX(price),
            from: window._address,
          })
          .executed();
        //console.log(buy);

        // 设置购买
        this.$axios
          .put(
            this.$store.state.api + "/nft/buy",
            this.$qs.stringify({
              id: tid, // 作品ID
              uid: uid, // 收购者
              sid: this.curruid, // 卖家
            })
          )
          .then(async (response) => {
            this.$router.push({ path: "/my" });
          });

        this.showbuy = false;
        this.show2 = false;
      } catch (error) {
        console.log(error);
        this.showbuy = false;
        this.show2 = false;
      }
    },
    // 拍卖产品
    async pai() {
      if (!this.paiprice) {
        alert("请输入竞拍价格");
        return;
      }

      if (this.paiprice <= this.price) {
        alert("请输入大于当前的价格");
        return;
      }

      let accounts = null;
        let _address = "";
        try {
          accounts = await window.conflux.enable();
        } catch (error) {}
        // 新版本手机
        if (window.isArrayFn(accounts)) {
          _address = accounts[0];
        } else {
          const accounts2 = await window.conflux.send({ method: "cfx_accounts" });
          _address = accounts2.result[0];
        }
        window._address = _address;

      localStorage.setItem("_address", _address);

      document.getElementById("accname").innerHTML =
        '<div class="qb_btn">' +
        _address.substring(0, 6) +
        "..." +
        _address.substring(45, _address.lenght) +
        "</div>";

      window.approvalpai();

      this.showpai = false;
      this.show2 = true;

      const price = this.paiprice;
      let tid = this.tid;
      const uid = this.uid; //  收购者
      console.log(tid);
      console.log(price);

      try {
        let collateral = await window.SHOPPAI.buySellerTicket(
          +tid
        ).estimateGasAndCollateral({
          from: window._address,
          value: +this.$drip.fromCFX(price),
        });

        const pai = await window.SHOPPAI.buySellerTicket(+tid)
          .sendTransaction({
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
            value: +this.$drip.fromCFX(price),
            from: window._address,
          })
          .executed();

        // 设置拍卖
        this.$axios
          .put(
            this.$store.state.api + "/pai",
            this.$qs.stringify({
              id: tid, // 作品ID
              uid: uid, // 收购者
              sid: this.curruid, // 卖家
              price: this.paiprice,
            })
          )
          .then(async (response) => {
            this.$toast.fail("出价成功，到期后取回");
          });

        this.showpai = false;
        this.show2 = false;
      } catch (error) {
        console.log(error);
        this.showpai = false;
        this.show2 = false;
      }
    },
    // 取回拍卖
    async getauction() {
      // 是否到期

      this.show2 = true;
      this.show = false;
      console.log(this.tid);
      try {
        let collateral = await window.SHOPPAI.buySellerTicket(
          +this.tid
        ).estimateGasAndCollateral({
          from: window._address,
          value: 0,
        });

        const pai = await window.SHOPPAI.buySellerTicket(+this.tid)
          .sendTransaction({
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
            value: 0,
            from: window._address,
          })
          .executed();
        this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
          "_address"
        );
        this.$axios
          .put(
            this.$store.state.api + "/fetchauction",
            this.$qs.stringify({
              id: this.tid,
            })
          )
          .then(async (response) => {
            this.init();
          });
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("have been pai")) {
          this.$toast.fail("已经拍卖成功，等待用户取回");
        } // 已经被拍
        else {
          this.show2 = false;
          this.$toast.fail("拍卖中,不能取回");
        }
      }
      this.show2 = false;
    },
    // 全部一口价
    goAll() {
      this.$router.push({ path: "/sell2all" });
    },
    goPai2All() {
      this.$router.push({ path: "/pai2/all" });
    }
  },
};
